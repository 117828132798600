<template>
  <div class="flex flex-wrap wrapper">
    <div 
      class="px-4 pb-24 pt-40"
      :class="{ 
        'w-7/12': isShowSidebar, 
        'w-full': !isShowSidebar
      }"
    >
      <p class="text-black font-bold head-title leading-none mb-10 px-4">Channels</p>
      <h3 class="font-sansDemi text-2xl text-black py-3 leading-none px-4">Add Channels</h3>

      <div class="flex flex-wrap mt-6">
        <BlockItem 
          v-for="(track, index) in bucketOptions"
          :key="`${track.id}${index}`"
          :item="track"
          :activeItem="selectedTrack"
          @setItem="setTrack"
        />
      </div>

      <div class="px-4">
        <Title :title="`Indexed Channels ${channels.length}/30`" titleSize="text-xl">
          <template v-slot:button>
            <button
              class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm font-mono font-medium"
              @click="addNewChannel"
            > 
              Add New Channel
            </button>
          </template>
        </Title>
      </div>
      <ChannelsLines 
        :channels="channels" 
        :classes="[ 'channels-list' ]" 
        :itemInnerClasses="'py-6'"
        class="px-4"
        @removeChannel="removeChannel"
      />

      <BottomNav @nextStep="nextStep" class="mt-20"/>
    </div>
    <div 
      class="px-8 pt-44 z-10 base-bg-darker"
      :class="{ 
        'w-5/12': isShowSidebar, 
        'hidden': !isShowSidebar
      }"
    >
      <component 
        :is="activeComponent"
        @close="closeSidebar"
        type="Channel"
        typeLower="channel"
      ></component>
    </div>
  </div>
</template>

<script>
  import { 
    bucketConfigChannelOptions, 
    warningToastConfig
  } from '@/helpers/constants'
  import ToastMessages from '@/data/toast_messages.json'
  import BottomNav from '@/components/Bucket/BottomNav'
  import Title from '@/components/Title'
  import ChannelsLines from '@/components/BucketsBlocks/ChannelsLines'
  import SidebarAddManually from '@/components/Bucket/SidebarAddManually'
  import SidebarAddCSV from '@/components/Bucket/SidebarAddCSV'
  import BlockItem from '@/components/Bucket/BlockItem'

  export default {
    name: 'BucketAdminChannels',
    components: {
      BottomNav,
      Title,
      ChannelsLines,
      SidebarAddManually,
      SidebarAddCSV,
      BlockItem
    },
    data(){
      return{
        bucketOptions: bucketConfigChannelOptions,
        selectedTrack: null,
        channels: [],
        isShowSidebar: false,
        activeComponent: ''
      }
    },
    methods: {
      setTrack(track){
        this.selectedTrack = track.id
        this.activeComponent = track.component
        this.isShowSidebar = true
      },
      nextStep(){
        if(this.selectedTrack != null){
          const track = this.bucketOptions.find(elem => elem.id == this.selectedTrack)
          this.$router.push(track.route)
        }else{
          this.$toasted.show(ToastMessages.buckets.bucketSelect, warningToastConfig)  
        }
      },
      addNewChannel(){

      },
      removeChannel(){

      },
      closeSidebar(){
        this.isShowSidebar = false
        this.selectedTrack = null
        this.activeComponent = ''
      }
    },
    async mounted(){
      let channels = await this.$axios.get('/dummy-data/buckets/private_channels.json')

      this.channels = JSON.parse(JSON.stringify(channels.data))
    }
  }
</script>

<style lang="scss" scoped>

</style>